import ContainarizedImage from "../../Components/ContainarizedImage/ContainarizedImage";
import {
  calcBackgroundColor,
  calculateDaysLeft,
  convertNumberToCurrency,
  formatDate,
  formatNumberIndian,
  formatPercentage,
  truncateArrayToString,
  truncateString,
} from "./CampaignDetailsPage.helper";

import * as types from "./CampaignDetailsPage.types";
import * as styles from "./CampaignDetailsPage.styles";
import TrimmedText from "../../Components/TrimmedText/TrimmedText";
import LabelValueCard from "../../Components/LabeValueCard/LabelValueCard";
import { BOX_SHADOWS, COLORS } from "../../Constants/styles.constants";
import Youtube from "../../Assets/Icons/youtube.png";
import Instagram from "../../Assets/Icons/instagram.png";
import lightBulb from "../../Assets/Icons/lightbulb.png";
import locationPin from "../../Assets/Icons/location-pin.png";
import like from "../../Assets/Icons/like.png";
import microphoneStand from "../../Assets/Icons/microphone-stand.png";
import userTag from "../../Assets/Icons/user-tag.png";
import usersGroupAlt from "../../Assets/Icons/users-group-alt.png";
import wallet from "../../Assets/Icons/wallet.png";
import instagramOutline from "../../Assets/Icons/instagram-outline.png";
import genderIcon from "../../Assets/Icons/gender.png";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";

export const SummaryComponent: React.FC<types.SummaryComponentProps> = ({
  brandName,
  endDate,
  campaignTitle,
  campaignDescription,
  prize,
  totalSlots,
  remainingSlots,
  platforms,
  brandLogo,
  compensationtype,
}) => {
  const SOCIAL_IMAGES = {
    Instagram,
    Youtube,
  };
  return (
    <styles.SummaryContainer>
      <styles.BrandContainer>
        <styles.BrandRow>
          <ContainarizedImage
            src={`${brandLogo}?a=${new Date()}`}
            alt="brand logo"
            width="50px"
            height="50px"
          />
          <styles.BrandName>{brandName}</styles.BrandName>
        </styles.BrandRow>
      </styles.BrandContainer>

      {endDate && (
        <styles.TimelineContainer>
          <styles.ClosesOnText>Closes on</styles.ClosesOnText>
          <styles.DateText>{formatDate(endDate)}</styles.DateText>
          <styles.DaysLeft
            style={calcBackgroundColor(
              parseInt(calculateDaysLeft(endDate)),
              "white"
            )}
          >
            {calculateDaysLeft(endDate)}
          </styles.DaysLeft>
        </styles.TimelineContainer>
      )}

      <styles.CampaignContainer>
        <styles.CampaignTitle>{campaignTitle}</styles.CampaignTitle>
        <TrimmedText text={campaignDescription} maxLength={150} />
      </styles.CampaignContainer>

      {(prize || totalSlots > 0 || platforms || compensationtype) && (
        <styles.OtherInfoContainer>
          {prize && (
            <>
              <styles.InfoBox>
                <styles.Label>Prize</styles.Label>
                <styles.Value>₹ {formatNumberIndian(prize)}</styles.Value>
              </styles.InfoBox>
              <styles.Separator />
            </>
          )}

          {totalSlots > 0 && (
            <>
              <styles.InfoBox>
                <styles.Label>Entries</styles.Label>
                <styles.Value>
                  {totalSlots - remainingSlots}/{totalSlots}
                </styles.Value>
              </styles.InfoBox>
              <styles.Separator />
            </>
          )}

          {compensationtype && (
            <>
              <styles.InfoBox>
                <styles.Label>Type</styles.Label>
                <styles.Value>{compensationtype}</styles.Value>
              </styles.InfoBox>
              <styles.Separator />
            </>
          )}

          {platforms && (
            <>
              <div>
                <styles.Label>Platforms</styles.Label>
                <styles.PlatformIcons>
                  {platforms.map((platform, index) => (
                    <img
                      key={index}
                      src={SOCIAL_IMAGES[platform.name]}
                      alt={platform.name}
                      style={{
                        height: platform.name === "Youtube" ? "19px" : "14.6px",
                        marginRight: "4px",
                      }}
                    />
                  ))}
                </styles.PlatformIcons>
              </div>
            </>
          )}
        </styles.OtherInfoContainer>
      )}
    </styles.SummaryContainer>
  );
};

export const EligiblilitySection: React.FC<types.EligibilitySectionProps> = ({
  eligibility,
}) => {
  let eligibilityObj: types.EligibilityObject = {
    Followers: "",
    Niche: "",
    Languages: "",
    Engagement: "",
    Locations: "",
  };

  eligibility.forEach((curr) => {
    eligibilityObj[curr.label] = curr.value;
  });

  const hasValidData = Object.values(eligibilityObj).some((value) => !!value);

  if (!hasValidData) {
    return <></>;
  }

  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: 12,
        margin: "24px 30px",
        boxShadow: BOX_SHADOWS.primary,
      }}
    >
      <p
        style={{
          fontWeight: "500",
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
        }}
      >
        Eligibility
      </p>
      {eligibilityObj.Followers && (
        <LabelValueCard
          label={"Followers"}
          value={`${formatNumberIndian(eligibilityObj.Followers)}+`}
          icon={userTag}
        />
      )}
      {eligibilityObj.Niche && eligibilityObj.Niche.length > 0 && (
        <LabelValueCard
          label={"Niche"}
          value={truncateString(eligibilityObj.Niche)[0]}
          remainingItems={truncateString(eligibilityObj.Niche)[1]}
          icon={lightBulb}
        />
      )}

      {eligibilityObj.Languages && (
        <LabelValueCard
          label={"Languages"}
          value={truncateString(eligibilityObj.Languages)[0]}
          remainingItems={truncateString(eligibilityObj.Languages)[1]}
          icon={microphoneStand}
        />
      )}
      {eligibilityObj.Engagement && (
        <LabelValueCard
          label={"Engagement"}
          value={`>${formatPercentage(eligibilityObj.Engagement)}`}
          icon={like}
        />
      )}
      {eligibilityObj.Locations && (
        <LabelValueCard
          label={"Locations"}
          value={truncateString(eligibilityObj.Locations)[0]}
          remainingItems={truncateString(eligibilityObj.Locations)[1]}
          icon={locationPin}
        />
      )}
    </div>
  );
};

export const CampaignDescriptionSection: React.FC<
  types.campaignDescriptionProps
> = ({ ageGroup, gender, locations, campaignDescription, deliverables }) => {
  const deliverablesMapping: Record<keyof types.Deliverables, string> = {
    image: "Image",
    reel: "Reel",
    story: "Story",
    video: "Video",
  };

  // Check if there's valid data to display
  const hasValidDescriptionData = !!campaignDescription;
  const hasValidTargetAudienceData =
    !!ageGroup.length || !!gender || !!locations.length;
  const hasValidDeliverablesData = Object.values(deliverables).some(
    (value) => value > 0
  );

  if (
    !hasValidDescriptionData &&
    !hasValidTargetAudienceData &&
    !hasValidDeliverablesData
  ) {
    return null; // Don't render the component if there's no valid data
  }

  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: 12,
        margin: "24px 30px",
        boxShadow: BOX_SHADOWS.primary,
      }}
    >
      {/* Description section */}
      {hasValidDescriptionData && (
        <>
          <p
            style={{
              fontFamily: "500",
              fontSize: "18px",
              marginBottom: "16px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Description
          </p>
          <p
            style={{
              fontFamily: "400",
              lineHeight: "24px",
              marginBottom: "16px",
              fontSize: "14px",
            }}
          >
            {campaignDescription}
          </p>
        </>
      )}

      {/* Target audience section */}
      {hasValidTargetAudienceData && (
        <div style={{ padding: "8px 0" }}>
          <p
            style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "16px",
              color: "#000",
            }}
          >
            Target audience
          </p>

          {ageGroup.length > 0 && (
            <LabelValueCard
              label={"Age Group"}
              value={
                truncateArrayToString(
                  ageGroup.map((curr) => curr),
                  3
                )[0]
              }
              remainingItems={
                truncateArrayToString(
                  ageGroup.map((curr) => curr),
                  3
                )[1]
              }
              icon={usersGroupAlt}
            />
          )}

          {gender && (
            <LabelValueCard label={"Gender"} value={gender} icon={genderIcon} />
          )}

          {locations.length > 0 && (
            <LabelValueCard
              label={"Locations"}
              value={
                truncateArrayToString(locations.map((curr) => curr.city))[0]
              }
              remainingItems={
                truncateArrayToString(locations.map((curr) => curr.city))[1]
              }
              icon={locationPin}
            />
          )}
        </div>
      )}

      {/* Deliverables section */}
      {hasValidDeliverablesData && (
        <div style={{ padding: "8px 0" }}>
          <p
            style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "16px",
              color: "#000",
            }}
          >
            Deliverables
          </p>
          <div
            style={{
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {Object.entries(deliverables).map(([key, value]) => {
              const deliverableKey = key as keyof types.Deliverables;
              if (value > 0) {
                return (
                  <div
                    key={key}
                    style={{
                      padding: "8px 12px",
                      border: `1px solid ${COLORS.GREY200}`,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <ContainarizedImage
                      height={"16px"}
                      width={"16px"}
                      src={instagramOutline}
                      contStyle={{ marginRight: "2px" }}
                      alt="instagram"
                    />
                    <p
                      style={{
                        fontFamily: "500",
                        lineHeight: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {value} {deliverablesMapping[deliverableKey]}
                    </p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}

      {/* Static conditions text */}
      <div style={{ padding: "8px 0" }}>
        <p style={{ fontSize: "14px" }}>
          We’re looking for someone who can promote our all-new choco drink. We
          prefer visiting our store while recording the video as we want to sell
          the store experience along with the drink.
        </p>
      </div>
    </div>
  );
};

export const CompensationSection: React.FC<types.CompensationSectionProps> = ({
  compensationType,
  prize,
}) => {
  // Check if there's valid data to display
  const hasValidData = !!compensationType || !!prize;

  if (!hasValidData) {
    return null; // Don't render the component if there's no valid data
  }

  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: 12,
        margin: "24px 30px",
        boxShadow: BOX_SHADOWS.primary,
      }}
    >
      <p
        style={{
          fontFamily: "500",
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
          fontWeight: "500",
        }}
      >
        Compensation
      </p>

      {/* Render only if compensationType is valid */}
      {compensationType && (
        <LabelValueCard
          label={"Compensation type"}
          value={compensationType}
          icon={wallet}
        />
      )}

      {/* Render payout only if prize is valid */}
      {compensationType === "Paid" && prize && (
        <div
          style={{
            padding: "16px",
            backgroundColor: COLORS.PURPLE_CARD,
            borderRadius: "8px",
            marginBottom: "16px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000",
              marginBottom: "13px",
              fontWeight: "500",
            }}
          >
            Payout
          </p>
          <div>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            >
              Instant payout
            </p>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500",
                color: COLORS.PRIMARY,
              }}
            >
              {convertNumberToCurrency(Number(prize))}
            </p>
          </div>
        </div>
      )}

      {/* Static conditions text */}
      <div style={{ padding: "8px 0" }}>
        <p
          style={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "16px",
            color: "#000",
          }}
        >
          Conditions
        </p>
        <p style={{ fontSize: "14px" }}>
          We’re looking for someone who can promote our all-new choco drink. We
          prefer visiting our store while recording the video as we want to sell
          the store experience along with the drink.
        </p>
      </div>
    </div>
  );
};

export const SupportSection = () => {
  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: 12,
        margin: "24px 30px",
      }}
    >
      <p
        style={{
          fontFamily: "500",
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
          fontWeight: "500",
        }}
      >
        Support
      </p>

      <p
        style={{
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
          fontWeight: "500",
        }}
      >
        FAQs
      </p>
    </div>
  );
};

export const Resources = ({
  hashtags,
  caption,
}: {
  hashtags: string[];
  caption: string;
}) => {
  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: 12,
        margin: "24px 30px",
        boxShadow: BOX_SHADOWS.primary,
      }}
    >
      <p
        style={{
          fontFamily: "500",
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
          fontWeight: "500",
        }}
      >
        Resources
      </p>
      {caption ? (
        <div
          style={{
            marginBottom: "16px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000",
              marginBottom: "13px",
              fontWeight: "500",
            }}
          >
            Caption
          </p>
          <div
            style={{
              padding: "16px",
              backgroundColor: COLORS.PURPLE_CARD,
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                fontFamily: "500",
                lineHeight: "20px",
                fontSize: "14px",
                marginBottom: "5px",
              }}
            >
              {caption}
            </p>
            <div
              style={{
                width: "100%",
                justifyContent: "flex-end ",
                display: "flex",
              }}
            >
              <CopyToClipboard textToBeCopied={caption} />
            </div>
          </div>
        </div>
      ) : null}
      {hashtags ? (
        <>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000",
              marginBottom: "13px",
              fontWeight: "500",
            }}
          >
            Hashtags
          </p>
          <div
            style={{
              padding: "16px",
              backgroundColor: COLORS.PURPLE_CARD,
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                fontFamily: "500",
                lineHeight: "20px",
                fontSize: "14px",
                marginBottom: "5px",
              }}
            >
              {hashtags?.join(" ")}
            </p>
            <div
              style={{
                width: "100%",
                justifyContent: "flex-end ",
                display: "flex",
              }}
            >
              <CopyToClipboard textToBeCopied={hashtags?.join(" ")} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

// export const DosAndDonts = ({rules}: {rules: ApplicationCampaignRules[]}) => {
//   return (
//     <View>
//       <Text style={[sectionStyles.sectionHeading]}>Rules</Text>
//       <View
//         style={{
//           marginBottom: aNV(16),
//         }}>
//         {rules.map((curr, i) => {
//           return (
//             <View
//               key={i}
//               style={{
//                 flexDirection: 'row',
//                 marginBottom: i !== rules.length - 1 ? aNV(6) : 0,
//                 alignItems: 'flex-start',
//               }}>
//               <Text style={{marginRight: aN(6)}}>{i + 1}.</Text>
//               <Text
//                 style={{
//                   flexShrink: 1,
//                 }}>
//                 {curr.rule}
//               </Text>
//             </View>
//           );
//         })}
//       </View>
//     </View>
//   );
// };

type ApplicationCampaignRules = {
  rule: string;
};

export const DosAndDonts = ({
  rules,
}: {
  rules: ApplicationCampaignRules[];
}) => {
  return (
    <div
      style={{
        padding: "12px",
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: "12px",
        margin: "24px 30px",
        boxShadow: BOX_SHADOWS.primary,
      }}
    >
      <p
        style={{
          fontFamily: "500",
          fontSize: "18px",
          marginBottom: "16px",
          color: "#000",
          fontWeight: "500",
        }}
      >
        Rules
      </p>
      <div
        style={{
          marginBottom: "16px",
        }}
      >
        {rules.map((curr, i) => {
          return (
            <div
              key={i}
              style={{
                marginBottom: i !== rules.length - 1 ? "6px" : 0,
                display: "flex",
                fontFamily: "500",
                lineHeight: "20px",
                fontSize: "14px",
              }}
            >
              <p style={{ marginRight: "6px" }}>{i + 1}.</p>
              <p
                style={{
                  flexShrink: 1,
                  fontFamily: "500",
                  lineHeight: "20px",
                  fontSize: "14px",
                  marginBottom: "5px",
                }}
              >
                {curr.rule}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
