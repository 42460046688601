import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

const isProduction = window.location.hostname === "app.ryme.ai";

const DSN = {
  prod: "https://19c2df88a0d8de5ca7b1a5a9d929f25e@o4508494256799744.ingest.us.sentry.io/4508494376402944",
  beta: "https://673da14ac2976e8dd0e906fb616b112c@o4508494256799744.ingest.us.sentry.io/4508494378369024",
};
// Initialize Sentry before rendering the  app
Sentry.init({
  dsn: isProduction ? DSN.prod : DSN.beta,
  integrations: [],
  debug: true,
  beforeSend(event) {
    // Inspect and allow all events to pass through
    console.log("Captured event:", event);
    return event;
  },
});

const container = document.getElementById("root"); // Ensure the id matches the root element in your HTML

if (container) {
  const root = createRoot(container);

  root.render(
    <Sentry.ErrorBoundary fallback={<div>An error occurred!</div>}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Sentry.ErrorBoundary>
  );
} else {
  console.error("Root container not found. Please check the HTML file.");
}
